<template>
    <div class="poweredby">
        <img src="https://ptscbd.com/assets/images/logo/logo_scbd-black.png" width="200" style="margin-bottom:30px;">
        <p>
            Background of the work or services that SCBD has provided :
        </p>
        <p>
            This is a nonprofit organization founded by an Indonesian politician from one of the country’s largest political parties. The organization specifically funds the education of underprivileged children up to the university level. Their biggest challenge is collecting, storing, retrieving, and extracting data related to these children and their families.
        </p>
        <p>
            SCBD offers a solution to more efficiently and accurately check and analyze underprivileged children in the area, utilizing AWS for data storage and hosting to enhance data security and minimize downtime, especially during the start of the school year (school admissions).
        </p>
        <p>
            &copy; 2024 - PT.SCBD<br><a href="https://www.ptscbd.com">https://www.ptscbd.com</a>
        </p>
      <div class=""></div>
    </div>
  </template>
  
  <script>
//   import { base_url, AlertPopup, goPage } from "@/assets/javascript/function";
//   import axios from "axios";
//   import Cookies from "js-cookie";
  
  export default {
    // data() {
    //   return {
    //    
    //   };
    // },
    // methods: {
    // },
  };
  </script>

  <style>
    .poweredby {
        margin-top:100px;
        text-align: center;
        padding: 100px;
    }
</style>
  